.notification-setting-page {
  padding: 24px 16px;
  min-height: calc(100vh - var(--nav-height));
  .notification-setting-container {
    max-width: 928px;
    margin: auto;
    h1 {
      margin: 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      color: #53525A;
    }
  }
  .content-container {
    padding: 32px 24px 40px;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.1029);
    margin-top: 13px;
    background-color: white;
    @media (max-width: 767px) {
      padding: 24px 16px;
    }
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 46px;
      margin: 0px;
      color: #53525A;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 18px;
      }
    }
    .divider {
      border-bottom: 1px solid #E5E5E5;
      margin-top: 16px;
    }
    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      margin: 24px 0px 0px;
    }
    .switch-container {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      p {
        color: #53525A;
        margin: 0px;
        line-height: 24px;
      }
    } 
  }
}
.select-with-search{
  overflow: unset !important;
  .select-search {
    width: 100%;
    &__options {
      padding: 0;
    }
    &__option {
      &.is-selected {
        background-color: #ff8c00;
      }
      &:not(.is-selected):hover {
        background-color: #ffedd6;
      }
    }
    &:not(.is-loading):not(.select-search--multiple) {
      .select-search__value::after {
        transform: rotate(180deg);
        border-right: 8px solid transparent;
        border-bottom: 8px solid #D8D8D8;
        border-left: 8px solid transparent;
      }
    }
    &__value::after {
      top: calc(50% - 12px);
      right: 11px;
    }
  }
  .suggestText {
    color: #888888;
    font-size: 12px;
    margin-top: 6px;
  }
  .select-search-select {
    background-color: white;
    --select-search-background: white;
    --select-search-text: #000;
    --select-search-highlight: #f5f5f5;
    --select-search-selected: #000;
    z-index: 3 !important;
    border: 1px solid #f5f5f5;
    .select-search-options {
      padding-left: 0;
      background-color: white;
      .select-search-is-selected {
        background-color: #f5f5f5;
      }
    }
  }
}
#root {
  min-height: calc(100 * var(--vh));
  background-color: #ffedd6 !important;
  --nav-height: 72px;
  @media (max-width: 767px) {
    --nav-height: 48px;
  }
}

body {
  overflow-x: hidden;
  background-color: #f1f3f4;
  -webkit-overflow-scrolling: touch;
  margin: 0;
}

a:focus {
  text-decoration: none;
}

.main-menu-open {
  overflow: hidden;
}
.container {
  padding: 0px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.container-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.container-flex > [class*="col-"] {
  display: flex;
  flex-direction: column;
}
.auto-trim {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.form-control {
  font-size: 16px;
  border-radius: 2px;
  border: 1px solid #ccc;
}
.password-icon {
  position: absolute;
  right: 14px;
  top: 24px;
  width: 18.3px;
  height: 12.5px;
}
.btn {
  border-radius: 2px;
  box-shadow: none !important;
}
.btn-block {
  border-radius: 2rem;
}
.full-box {
  border-radius: 0px;
  margin: 0px -15px;
}
#tab-list-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.1);
  width: 100%;
  position: fixed;
  top: 72px;
  z-index: 9;
}

#tab-list-container a {
  text-decoration: none;
  color: #888888;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  background-color: #fff;
  padding: 11px 55px 13px;
  border: 0;
  font-size: 18px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  #tab-list-container a {
    padding: 15px 0;
    width: 50%;
    text-align: center;
  }
}

#tab-list-container .active {
  color: #ff5d00;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
  border-bottom: 2px solid #ff5d00;
  font-size: 18px;
}
.btn-round {
  border-radius: 2rem !important;
}
.small-margin-left {
  margin-left: 10px;
}
.loading-bar {
  height: 3px;
  background-color: #f0ac58;
  position: absolute;
  z-index: 1;
}
/* Bootstrap grid on safari */
.row:before,
row:after {
  display: none !important;
}
.ellip-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellip-overflow span {
  color: #000;
}
.mid-button {
  float: none;
  font-weight: bolder;
  font-size: 28px;
  line-height: 50px;
  background: -webkit-linear-gradient(#ff5d00, #ff8c00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#main-content {
  background-color: #ffedd6 !important;
  min-height: calc(100vh - var(--nav-height));
  min-height: calc(100 * var(--vh) - var(--nav-height));
  @media (max-width: 767px) {
    #main-content {
      padding: 0px 0px;
    }
  }
}

.meshi-btn {
  border: 1px solid coral;
  background-image: -webkit-linear-gradient(bottom, #ff9900, coral);
  background-image: linear-gradient(to bottom, #ff9900, coral);
  margin: 0 5px;
  color: white;
}
.meshi-btn-o {
  border: unset;
  background-color: white;
  margin: 0 5px;
  color: #ff8c00;
}
.meshi-btn-o-border {
  border: 1px solid #bcbcbc;
}
.meshi-confirm {
  min-width: 120px;
  margin-left: auto;
  margin-right: auto;
}
.btn-radius {
  border-radius: 5px;
}
.meshi-btn-locked {
  border: 1px solid #ec827f;
  background-image: -webkit-linear-gradient(bottom, #fb4745, #dc1f22);
  background-image: linear-gradient(to bottom, #fb4745, #dc1f22);
  margin: 0 5px;
  color: white;
}
.meshi-btn-cancel {
  border: 1px solid #dcdcdc;
  background-color: #bcbcbc;
  margin: 0 5px;
  color: white;
}
.meshi-btn-warning-o {
  border: 1px solid #bcbcbc;
  background-color: unset;
  color: #ff5d00;
}
.meshi-btn-event-fininshed {
  border: 1px solid #dcdcdc;
  background-color: #bcbcbc;
  margin: 0 5px;
  color: white;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  #tab-list-container {
    top: 48px;
  }
}

.fade.modal-backdrop.in {
  opacity: 0.85;
}

.marker-location {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
}
.marker-location img {
  width: 20px;
  height: 24px;
  transform: translate(-50%, -100%);
}
.marker-location .info {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: -36px;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  width: auto;
  white-space: nowrap;
}

.marker-location:before {
  content: "";
  position: absolute;
  top: -25px;
  left: 0;
  transform: translate(-50%, -100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid white;
  z-index: 1;
}

/* prevent safari iOS 9.x zoom when focus on input of modal*/
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 767px) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.pre-line {
  white-space: pre-line;
}

.bold {
  font-weight: 700;
}

#tag-search {
  width: 1408px;
  padding-top: 40px;
  margin: 0px auto 24px;
  font-size: 18px;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
  color: #262626;
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    margin-right: 16px;
  }

  @media only screen and (max-width: 1440px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 24px;
    font-size: 16px;

    img {
      margin-right: 8px;
      width: 20px;
    }
  }
}

.fs-32 {
  font-size: 32px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-18 {
  font-size: 18px;
}

.w-100 {
  width: 100%;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 16px;
}

.mt-4 {
  margin-top: 24px;
}

.mt-5 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-3 {
  margin-left: 16px;
}

.ml-4 {
  margin-left: 24px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-3 {
  margin-right: 16px;
}

.mr-4 {
  margin-right: 24px;
}

.mb-4 {
  margin-bottom: 24px;
}

.mb-5 {
  margin-bottom: 32px;
}

.bold {
  font-weight: 700;
}
.text-danger {
  color: #a94442 !important;
}
.ellipsis-text-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.pagination {
  list-style-type: none;

  .page-item {
    &.active {
      .page-link {
        border-color: #FF5D00;
        background: transparent;
        color: #FF5D00;
        border-radius: 8px;
        font-weight: 600;
      }
    }

    .page-link {
      border-color: transparent;
      background: transparent;
      color: #888;
      cursor: pointer;
    }
  }

  a {
    color: #888;
  }
}

.help-block {
  margin-top: 2px;
  font-size: 14px;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}